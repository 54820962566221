<template>
  <div>
    <ElRow :gutter="20" justify="center" align="middle">
      <ElCol :span="24" :xs="24" :lg="24">
        <ElForm
          require-asterisk-position="right"
          ref="ruleFormRef"
          :rules="inputRules"
          :model="form"
          label-position="top"
        >
          <ElRow :gutter="20">
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem prop="type" :label="$t('type')">
                <ElSelect
                  v-model="form.type"
                  :placeholder="$t('select_an_option')"
                  class="w-full"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  clearable
                  clear-icon="Close"
                >
                  <el-option
                    v-for="item in types"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </ElSelect>
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem prop="number" :label="$t('number')">
                <ElInput
                  v-model="form.number"
                  @keydown.enter.prevent="submit(ruleFormRef)"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem prop="status" :label="$t('status')">
                <ElSelect
                  v-model="form.status"
                  :placeholder="$t('select_an_option')"
                  class="w-full"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  clearable
                  clear-icon="Close"
                >
                  <el-option
                    v-for="item in statuses"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </ElSelect>
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem prop="payment_status" :label="$t('payment_status')">
                <ElSelect
                  v-model="form.payment_status"
                  :placeholder="$t('select_an_option')"
                  class="w-full"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  clearable
                  clear-icon="Close"
                >
                  <el-option
                    v-for="item in statuses"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </ElSelect>
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem prop="date" :label="$t('date')">
                <ElDatePicker
                  v-model="form.date"
                  class="!w-full"
                  type="date"
                  format="DD/MM/YYYY"
                  popper-class="custom-datepicker"
                  :editable="false"
                  :clearable="false"
                  @change="form.date = formatDateRaw($event)"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem prop="amount_owed" :label="$t('amount_owed')">
                <ElInputNumber
                  v-model="form.amount_owed"
                  type="number"
                  min="0"
                  step="0.01"
                  @keydown.enter.prevent="submit(ruleFormRef)"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem prop="subtotal" :label="$t('subtotal')">
                <ElInputNumber
                  v-model="form.subtotal"
                  type="number"
                  min="0"
                  step="0.01"
                  @keydown.enter.prevent="submit(ruleFormRef)"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem prop="total" :label="$t('total')">
                <ElInputNumber
                  v-model="form.total"
                  type="number"
                  min="0"
                  step="0.01"
                  @keydown.enter.prevent="submit(ruleFormRef)"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem
                prop="serial_uuid"
                :label="$t('serial')"
                @keydown.enter.prevent="submit(ruleFormRef)"
                class="autocomplete-new-entity"
              >
                <el-select-v2
                  :key="getSerialKey()"
                  v-loading="getSerialKey() === 0"
                  v-model="form.serial_uuid"
                  :remote-method="searchSerials"
                  :placeholder="$t('select_a_serial')"
                  remote
                  filterable
                  autocomplete
                  clearable
                  clear-icon="Close"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  :options="getSearchSerials()"
                  style="width: 100%"
                />
                <Icons
                  name="24px/group_purple"
                  class="new-entity-button"
                  @click="showNewSerialDialog = true"
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem
                prop="subject_uuid"
                :label="$t('subject')"
                class="autocomplete-new-entity"
                :class="form.subject_uuid ? 'autocomplete-redirection' : ''"
              >
                <el-select-v2
                  :key="getCompanyKey()"
                  v-loading="getCompanyKey() === 0"
                  v-model="form.subject_uuid"
                  :remote-method="searchCompanies"
                  :placeholder="$t('select_a_company')"
                  remote
                  filterable
                  autocomplete
                  clearable
                  clear-icon="Close"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  :options="getSearchCompanies()"
                  style="width: 100%"
                />
                <Icons
                  name="24px/group_purple"
                  class="new-entity-button"
                  @click="showNewCompanyDialog = true"
                />
                <Icons
                  v-if="form.subject_uuid"
                  name="24px/arrow_top_right_purple"
                  class="new-entity-button"
                  @click="
                    newWindowItem('/finances/companies', {
                      uuid: form.subject_uuid
                    })
                  "
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem
                prop="issuer_uuid"
                :label="$t('issuer')"
                class="autocomplete-new-entity"
                :class="form.issuer_uuid ? 'autocomplete-redirection' : ''"
              >
                <el-select-v2
                  :key="getIssuerKey()"
                  v-loading="getIssuerKey() === 0"
                  v-model="form.issuer_uuid"
                  :remote-method="searchIssuers"
                  :placeholder="$t('select_a_company')"
                  remote
                  filterable
                  autocomplete
                  clearable
                  clear-icon="Close"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  :options="getSearchIssuers()"
                  style="width: 100%"
                />
                <Icons
                  name="24px/group_purple"
                  class="new-entity-button"
                  @click="showNewIssuerDialog = true"
                />
                <Icons
                  v-if="form.issuer_uuid"
                  name="24px/arrow_top_right_purple"
                  class="new-entity-button"
                  @click="
                    newWindowItem('/finances/companies', {
                      uuid: form.issuer_uuid
                    })
                  "
                />
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12" />
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem prop="billable_type" :label="$t('billable_type')">
                <ElSelect
                  v-model="form.billable_type"
                  :placeholder="$t('select_a_type')"
                  style="width: 100%"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  clearable
                  clear-icon="Close"
                >
                  <el-option
                    v-for="item in billableTypes"
                    :key="item"
                    :label="$t(item.toLowerCase())"
                    :value="item"
                  />
                </ElSelect>
              </ElFormItem>
            </ElCol>
            <ElCol :span="containerWidth <= 700 ? 24 : 12" :lg="12">
              <ElFormItem
                prop="billable_uuid"
                :label="$t('company')"
                class="autocomplete-new-entity"
                :class="form.billable_uuid ? 'autocomplete-redirection' : ''"
              >
                <el-select-v2
                  :key="getBillableKey()"
                  v-loading="getBillableKey() === 0"
                  v-model="form.billable_uuid"
                  :remote-method="searchBillables"
                  :placeholder="$t('select_a_company')"
                  remote
                  filterable
                  autocomplete
                  clearable
                  clear-icon="Close"
                  :fallback-placements="['top', 'bottom']"
                  :popper-options="getSelectPopperOptions()"
                  popper-class="custom-selector"
                  :options="getSearchBillables()"
                  style="width: 100%"
                />
                <Icons
                  name="24px/group_purple"
                  class="new-entity-button"
                  @click="showNewBillableDialog = true"
                />
                <Icons
                  v-if="form.billable_uuid"
                  name="24px/arrow_top_right_purple"
                  class="new-entity-button"
                  @click="
                    newWindowItem('/finances/companies', {
                      uuid: form.billable_uuid
                    })
                  "
                />
              </ElFormItem>
            </ElCol>
            <ElCol v-if="width > 700" :span="16" :lg="16" />
            <ElCol
              :span="width <= 700 ? 24 : 4"
              :lg="4"
              class="cancel-button"
            >
              <ElButton
                style="width: 100%"
                type="secondary"
                @click="$emit('cancel')"
              >
                {{ $t('cancel') }}
              </ElButton>
            </ElCol>
            <ElCol
              :span="width <= 700 ? 24 : 4"
              :lg="4"
              class="confirm-button"
            >
              <ElButton
                :loading="loading"
                :disabled="loading"
                type="primary"
                style="width: 100%"
                @click="submit(ruleFormRef)"
              >
                {{ $t('save') }}
              </ElButton>
            </ElCol>
          </ElRow>
        </ElForm>
      </ElCol>
    </ElRow>
    <el-dialog
      v-model="showNewSerialDialog"
      :width="containerWidth <= 600 ? '100%' : '70vw'"
      :close-on-click-modal="false"
    >
      <template #header>
        <span class="dashboard-title">
          {{ $t('create_serial') }}
        </span>
      </template>
      <QuickCreateSerialDialog
        @create=";(showNewSerialDialog = false), createSerial($event)"
        @cancel="showNewSerialDialog = false"
      />
    </el-dialog>
    <el-dialog
      v-model="showNewCompanyDialog"
      :width="containerWidth <= 600 ? '100%' : '70vw'"
      :close-on-click-modal="false"
    >
      <template #header>
        <span class="dashboard-title">
          {{ $t('create_company') }}
        </span>
      </template>
      <QuickCreateCompanyDialog
        @create=";(showNewCompanyDialog = false), createCompany($event)"
        @cancel="showNewCompanyDialog = false"
      />
    </el-dialog>
    <el-dialog
      v-model="showNewIssuerDialog"
      :width="containerWidth <= 600 ? '100%' : '70vw'"
      :close-on-click-modal="false"
    >
      <template #header>
        <span class="dashboard-title">
          {{ $t('create_company') }}
        </span>
      </template>
      <QuickCreateCompanyDialog
        @create=";(showNewIssuerDialog = false), createIssuer($event)"
        @cancel="showNewIssuerDialog = false"
      />
    </el-dialog>
    <el-dialog
      v-model="showNewBillableDialog"
      :width="containerWidth <= 600 ? '100%' : '70vw'"
      :close-on-click-modal="false"
    >
      <template #header>
        <span class="dashboard-title">
          {{ $t('create_company') }}
        </span>
      </template>
      <QuickCreateCompanyDialog
        @create=";(showNewBillableDialog = false), createBillable($event)"
        @cancel="showNewBillableDialog = false"
      />
    </el-dialog>
  </div>
</template>

<script setup>
import useVuelidate from '@vuelidate/core'
import { required, minLength } from '@vuelidate/validators'
import { reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import CREATE_INVOICE from '~/graphql/invoices/mutation/createInvoice.gql'

const { t } = useI18n()
const { $showError, $showSuccess, $sentry } = useNuxtApp()

const form = reactive({
  type: '',
  number: '',
  status: '',
  payment_status: '',
  subtotal: 0,
  total: 0,
  amount_owed: 0,
  serial_uuid: '',
  subject_uuid: '',
  issuer_uuid: '',
  billable_type: 'Company',
  billable_uuid: ''
})

const emits = defineEmits(['create'])
const { width } = useBreakpoints()

const ruleFormRef = ref()
const loading = ref(false)
const showNewSerialDialog = ref(false)
const showNewCompanyDialog = ref(false)
const showNewIssuerDialog = ref(false)
const showNewBillableDialog = ref(false)
let { newWindowItem } = getTableInitialVariables()
const types = getInvoiceTypes()
const statuses = getInvoiceStatus()

const rules = {
  type: { required },
  number: { required },
  status: { required },
  date: { required },
  payment_status: { required },
  serial_uuid: { required },
  subject_uuid: { required },
  issuer_uuid: { required },
  billable_type: { required },
  billable_uuid: { required }
}

onMounted(() => {
  initialCompanies()
  initialSerials()
})

const v$ = useVuelidate(rules, form)

const inputRules = reactive({
  type: [
    {
      required: true,
      message: t('type_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  number: [
    {
      required: true,
      message: t('number_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  date: [
    {
      required: true,
      message: t('date_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  status: [
    {
      required: true,
      message: t('status_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  payment_status: [
    {
      required: true,
      message: t('status_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  serial_uuid: [
    {
      required: true,
      message: t('serial_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  subject_uuid: [
    {
      required: true,
      message: t('subject_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  issuer_uuid: [
    {
      required: true,
      message: t('issuer_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  billable_type: [
    {
      required: true,
      message: t('billable_type_is_required'),
      trigger: ['blur', 'change']
    }
  ],
  billable_uuid: [
    {
      required: true,
      message: t('billable_uuid_is_required'),
      trigger: ['blur', 'change']
    }
  ]
})

const billableTypes = computed(() => {
  return ['Company']
})

const submit = async (formEl) => {
  v$.value.$touch()
  await formEl.validate((valid, fields) => {
    if (fields && Object.values(fields)?.length) {
      for (const field of Object.values(fields)) {
        if (field[0]?.message) {
          $showError(field[0].message)
          return false
        }
      }
    }
  })
  if (v$.value.$invalid) {
    $showError(t('form_is_invalid'))
  } else {
    create()
  }
}

const create = async () => {
  loading.value = true
  const response = await mutation(CREATE_INVOICE, {
    type: form.type,
    number: form.number,
    status: form.status,
    payment_status: form.payment_status,
    date: form.date,
    serial_uuid: form.serial_uuid,
    subject_uuid: form.subject_uuid,
    issuer_uuid: form.issuer_uuid,
    billable_type: `App\\Models\\${form.billable_type}`,
    billable_uuid: form.billable_uuid,
    amount_owed: form.amount_owed
      ? parseFloat(parseFloat(form.amount_owed * 100).toFixed(2))
      : 0,
    subtotal: form.subtotal
      ? parseFloat(parseFloat(form.subtotal * 100).toFixed(2))
      : 0,
    total: form.total ? parseFloat(parseFloat(form.total * 100).toFixed(2)) : 0
  })
  if (response.error) {
    loading.value = false
    $showError(response.error, t)
    $sentry(response.error, 'create')
  }
  if (response.result) {
    $showSuccess(t('new_entity_success'))
    v$.value.$reset()
    emits('create', response.result?.data?.createInvoice ?? null)
    loading.value = false
  }
}

const createSerial = (event) => {
  if (event?.uuid) {
    form.serial_uuid = event.uuid
    form.serial = event
    searchSerials(event.prefix, form.serial, null, true)
  }
}

const createCompany = (event) => {
  if (event?.uuid) {
    form.subject_uuid = event.uuid
    form.subject = event
    searchCompanies(event.business_name, form.subject, null, true)
  }
}

const createIssuer = (event) => {
  if (event?.uuid) {
    form.issuer_uuid = event.uuid
    form.issuer = event
    searchIssuers(event.business_name, form.issuer, null, true)
  }
}

const createBillable = (event) => {
  if (event?.uuid) {
    form.billable_uuid = event.uuid
    form.billable = event
    searchIssuers(event.business_name, form.billable, null, true)
  }
}
</script>
